import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

const DarkModeToggle = ({ darkMode, toggleDarkMode }) => {
    return (
        <button
            className="absolute top-4 right-4 p-2 rounded-full bg-bg-secondary text-text hover:bg-accent transition-colors duration-300"
            onClick={toggleDarkMode}
            aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
            <FontAwesomeIcon
                icon={darkMode ? faSun : faMoon}
                className="text-2xl"
            />
        </button>
    );
};

export default DarkModeToggle;