import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fortawesome/fontawesome-free/css/all.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Use pagehide instead of unload for cleanup
window.addEventListener('pagehide', function () {
  // Perform any necessary cleanup here
});

// Remove any beforeunload listeners when they're no longer needed
const beforeUnloadHandler = function (e) {
  // Your beforeunload logic here
};

window.addEventListener('beforeunload', beforeUnloadHandler);

// Example of removing the listener when it's no longer needed:
// window.removeEventListener('beforeunload', beforeUnloadHandler);

// Avoid using window.opener
if (window.opener) {
  console.warn('Using window.opener may prevent bfcache from working');
}

// Handle page visibility changes
document.addEventListener('visibilitychange', function () {
  if (document.hidden) {
    // Page is hidden, pause any background activities
  } else {
    // Page is visible again, resume activities if needed
  }
});