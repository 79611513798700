import React, { useState } from 'react';

const HowToPlay = () => {
    const [showInstructions, setShowInstructions] = useState(false);

    const toggleInstructions = () => {
        setShowInstructions(!showInstructions);
    };

    return (
        <div className="how-to-play mt-4">
            <button
                className={`game-button w-full py-3 rounded-lg text-lg font-semibold transition-colors duration-300 ${
                    showInstructions ? 'bg-secondary text-bg' : 'bg-accent text-bg'
                }`}
                onClick={toggleInstructions}
            >
                How to Play
            </button>
            {showInstructions && (
                <div className="instructions mt-4 p-4 rounded-lg bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-700">
                    <ol className="list-decimal list-inside space-y-2">
                        <li>Click "Play Game" to start a new game.</li>
                        <li>Numbers between 1 and 1,000 will be randomly generated one at a time above the number grid.</li>
                        <li>For each number, decide whether to place it on your grid or pass.</li>
                        <li>The number must go in an empty slot while maintaining ascending order.</li>
                        <li>You have three passes available. Use them wisely!</li>
                        <li>Passed numbers will be displayed in the "Skipped Numbers" section.</li>
                        <li>Your goal is to fill as many slots as possible.</li>
                        <li>The game ends when either all 20 slots are filled or you can't place a number and have no passes left.</li>
                        <li>Your score is the number of slots you successfully filled.</li>
                        <li>Try to achieve the highest score possible!</li>
                    </ol>
                </div>
            )}
        </div>
    );
};

export default HowToPlay;