import React, { createContext, useContext } from 'react';
import useGameState from '../hooks/useGameState';
import { checkValidPlacement, checkIfCanPlace } from '../utils/gameLogic';

const GameContext = createContext();

export const useGame = () => useContext(GameContext);

export const GameProvider = ({ children }) => {
    const {
        board,
        currentNumber,
        bestScore,
        currentScore,
        gameOverMessage,
        startGame,
        placeNumber,
        isGameOver,
        passNumber,
        passesLeft,
        skippedNumbers,
    } = useGameState();

    const validPlacements = isGameOver
        ? []
        : board.reduce((acc, _, index) => {
            if (board[index] === null && checkValidPlacement(board, index, currentNumber)) {
                acc.push(index);
            }
            return acc;
        }, []);

    const canPlace = !isGameOver && checkIfCanPlace(currentNumber, board);

    return (
        <GameContext.Provider
            value={{
                board,
                currentNumber,
                bestScore,
                currentScore,
                gameOverMessage,
                startGame,
                placeNumber,
                validPlacements,
                canPlace,
                isGameOver,
                passNumber,
                passesLeft,
                skippedNumbers,
            }}
        >
            {children}
        </GameContext.Provider>
    );
};