import React from 'react';

const SkippedNumbers = ({ skippedNumbers }) => {
    if (skippedNumbers.length === 0) return null;

    return (
        <div className="skipped-numbers mt-4">
            <h3 className="text-lg font-semibold mb-2">Skipped Numbers:</h3>
            <div className="flex flex-wrap gap-2">
                {skippedNumbers.map((number, index) => (
                    <div key={index} className="bg-gray-200 dark:bg-gray-700 px-2 py-1 rounded">
                        {number}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SkippedNumbers;