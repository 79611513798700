import React from 'react';

const GameStatus = ({ currentNumber, currentScore, bestScore, gameOverMessage }) => {
    return (
        <div className="game-status">
            {currentNumber && (
                <div className="current-number text-center p-4 rounded-lg mb-4">
                    Current number: {currentNumber}
                </div>
            )}
            <div className="score-container mt-4 text-center">
                <p>Current Score: {currentScore}</p>
                <p>Best Score: {bestScore}</p>
            </div>
            {gameOverMessage && (
                <div className="game-over-message mt-4 p-4 bg-red-100 dark:bg-red-900 text-red-700 dark:text-red-100 rounded">
                    {gameOverMessage}
                </div>
            )}
        </div>
    );
};

export default GameStatus;