import { useState, useCallback, useEffect } from 'react';
import { generateNewNumber, checkIfCanPlace } from '../utils/gameLogic';

const MAX_PASSES = 3;

const useGameState = () => {
    const [board, setBoard] = useState(Array(20).fill(null));
    const [currentNumber, setCurrentNumber] = useState(null);
    const [bestScore, setBestScore] = useState(0);
    const [currentScore, setCurrentScore] = useState(0);
    const [gameOverMessage, setGameOverMessage] = useState('');
    const [isGameOver, setIsGameOver] = useState(true);
    const [passesLeft, setPassesLeft] = useState(MAX_PASSES);
    const [skippedNumbers, setSkippedNumbers] = useState([]);

    const startGame = useCallback(() => {
        setBoard(Array(20).fill(null));
        setCurrentNumber(generateNewNumber());
        setCurrentScore(0);
        setGameOverMessage('');
        setIsGameOver(false);
        setPassesLeft(MAX_PASSES);
        setSkippedNumbers([]);
    }, []);

    const placeNumber = useCallback((index) => {
        if (board[index] === null) {
            const newBoard = [...board];
            newBoard[index] = currentNumber;
            setBoard(newBoard);
            setCurrentScore(score => score + 1);
            setCurrentNumber(generateNewNumber(newBoard));
        }
    }, [board, currentNumber]);

    const passNumber = useCallback(() => {
        if (passesLeft > 0) {
            setSkippedNumbers(prev => [...prev, currentNumber]);
            setCurrentNumber(generateNewNumber([...board, currentNumber]));
            setPassesLeft(prev => prev - 1);
        }
    }, [board, currentNumber, passesLeft]);

    useEffect(() => {
        if (!checkIfCanPlace(currentNumber, board) && passesLeft === 0) {
            setGameOverMessage(`Game Over! Your score: ${currentScore}`);
            setIsGameOver(true);
            if (currentScore > bestScore) {
                setBestScore(currentScore);
            }
        }
    }, [board, currentNumber, currentScore, bestScore, passesLeft]);

    return {
        board,
        currentNumber,
        bestScore,
        currentScore,
        gameOverMessage,
        startGame,
        placeNumber,
        isGameOver,
        passNumber,
        passesLeft,
        skippedNumbers,
    };
};

export default useGameState;