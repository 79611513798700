import React from 'react';

const Slot = ({ index, number, placeNumber, currentNumber, isValidPlacement }) => {
    const handleDragOver = (e) => {
        e.preventDefault();
        if (e.dataTransfer) {
            e.dataTransfer.dropEffect = "move";
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const draggedNumber = e.dataTransfer && e.dataTransfer.getData ? parseInt(e.dataTransfer.getData("text"), 10) : null;
        if (draggedNumber === currentNumber && isValidPlacement) {
            placeNumber(index);
        }
    };

    const handleClick = () => {
        if (isValidPlacement) {
            placeNumber(index);
        }
    };

    return (
        <div
            className={`slot w-12 h-12 rounded flex items-center justify-center transition-all duration-300 ease-in-out
                ${number !== null
                    ? 'bg-primary-color text-bg-color font-bold shadow-md transform scale-105'
                    : isValidPlacement
                        ? 'bg-valid-placement-bg text-slot-text border-2 border-valid-placement-border cursor-pointer'
                        : 'bg-slot-bg text-slot-text cursor-not-allowed'
                }`}
            onClick={handleClick}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {number !== null ? (
                <span className="text-lg">{number}</span>
            ) : (
                <span className="text-sm opacity-50">{index + 1}</span>
            )}
        </div>
    );
};

export default Slot;