export const generateNewNumber = (board = []) => {
    let newNumber;
    do {
        newNumber = Math.floor(Math.random() * 1000) + 1;
    } while (board.includes(newNumber));
    return newNumber;
};

export const checkIfCanPlace = (number, currentBoard) => {
    const filledPositions = currentBoard.reduce((acc, val, index) => val !== null ? [...acc, index] : acc, []);

    if (filledPositions.length === 0) return true;

    const firstFilledIndex = filledPositions[0];
    const lastFilledIndex = filledPositions[filledPositions.length - 1];

    if (number < currentBoard[firstFilledIndex] && firstFilledIndex > 0) return true;
    if (number > currentBoard[lastFilledIndex] && lastFilledIndex < currentBoard.length - 1) return true;

    for (let i = 0; i < filledPositions.length - 1; i++) {
        const currentPos = filledPositions[i];
        const nextPos = filledPositions[i + 1];
        if (currentBoard[currentPos] < number && number < currentBoard[nextPos] && nextPos - currentPos > 1) {
            return true;
        }
    }

    return false;
};

export const checkValidPlacement = (board, index, currentNumber) => {
    const prevNumber = board.slice(0, index).filter(n => n !== null).pop();
    const nextNumber = board.slice(index + 1).find(n => n !== null);

    return (prevNumber === undefined || prevNumber < currentNumber) &&
        (nextNumber === undefined || currentNumber < nextNumber);
};