import React, { useState } from 'react';
import './styles/App.css';
import Board from './components/Board/Board';
import GameStatus from './components/GameStatus/GameStatus';
import DarkModeToggle from './components/DarkModeToggle/DarkModeToggle';
import HowToPlay from './components/HowToPlay/HowToPlay';
import SkippedNumbers from './components/SkippedNumbers/SkippedNumbers';
import { GameProvider, useGame } from './contexts/GameContext';
import useDarkMode from './hooks/useDarkMode';

const GameContent = () => {
    const { 
        board, 
        currentNumber, 
        bestScore, 
        currentScore, 
        gameOverMessage, 
        startGame, 
        placeNumber, 
        validPlacements, 
        passNumber, 
        passesLeft, 
        skippedNumbers 
    } = useGame();
    const [darkMode, toggleDarkMode] = useDarkMode();
    const [gameStarted, setGameStarted] = useState(false);

    const handleStartGame = () => {
        startGame();
        setGameStarted(true);
    };

    const noValidPlacements = validPlacements.length === 0;

    return (
        <div className={`App max-w-md mx-auto p-4 relative ${darkMode ? 'dark' : ''} bg-bg text-text`}>
            <h1 className="text-2xl font-bold mb-4">20 Number Challenge</h1>
            <DarkModeToggle darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
            <GameStatus
                currentNumber={currentNumber}
                currentScore={currentScore}
                bestScore={bestScore}
                gameOverMessage={gameOverMessage}
            />
            <Board board={board} placeNumber={placeNumber} currentNumber={currentNumber} validPlacements={validPlacements} />
            <div className="button-container mt-4 flex gap-2">
                <button 
                    className="game-button w-full py-3 rounded-lg text-lg font-semibold bg-accent text-bg hover:bg-accent-hover" 
                    onClick={handleStartGame}
                >
                    {gameStarted ? "New Game" : "Play Game"}
                </button>
                {gameStarted && (
                    <button 
                        className={`game-button w-full py-3 rounded-lg text-lg font-semibold 
                            ${passesLeft > 0 
                                ? noValidPlacements
                                    ? 'bg-yellow-400 text-gray-800 hover:bg-yellow-500' // Highlighted state
                                    : 'bg-gray-400 text-gray-600 hover:bg-gray-500'
                                : 'bg-secondary text-bg hover:bg-secondary-hover cursor-not-allowed'
                            }`}
                        onClick={passNumber}
                        disabled={passesLeft === 0}
                    >
                        Pass ({passesLeft})
                    </button>
                )}
            </div>
            {skippedNumbers.length > 0 && <SkippedNumbers skippedNumbers={skippedNumbers} />}
            <HowToPlay />
        </div>
    );
};

function App() {
    return (
        <GameProvider>
            <GameContent />
        </GameProvider>
    );
}

export default App;