import React from 'react';
import Slot from '../Slot/Slot';

const Board = ({ board, placeNumber, currentNumber, validPlacements }) => {
    return (
        <div className="board grid grid-cols-5 gap-2 mb-4">
            {board.map((number, index) => (
                <Slot
                    key={index}
                    index={index}
                    number={number}
                    placeNumber={placeNumber}
                    currentNumber={currentNumber}
                    isValidPlacement={validPlacements.includes(index)}
                />
            ))}
        </div>
    );
};

export default Board;